// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import {getMessaging} from "firebase/messaging" 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7Nt8W8s3dZL4XNuRA0mLTy3LAWvFhLa0",
  authDomain: "psycure-58e6d.firebaseapp.com",
  projectId: "psycure-58e6d",
  storageBucket: "psycure-58e6d.appspot.com",
  messagingSenderId: "1057385977905",
  appId: "1:1057385977905:web:5ab04a01a9bda514a65d10",
  measurementId: "G-R3FN57PMTC",
  databaseURL: "https://psycure-58e6d-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const messaging = getMessaging(app);
export const timeStamp = Timestamp;
export const projectStorage = getStorage();
export const realTimeDB = getDatabase(app);
